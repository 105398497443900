/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  SiteVariablesFragment,
  ListingsActivePageFragment,
  PropertyFragment,
} from "src/components/QueryFragments"

import "src/assets/scss/material-kit-react.scss"

import ListingsView from "../views/ListingsView"

const ListingsActivePage = ({ location }) => {
  const {
    contentfulWebsiteGlobalVariables,
    contentfulPageActiveListings,
    allContentfulProperty,
  } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteGlobalVariables {
          ...SiteVariablesFragment
        }
        contentfulPageActiveListings {
          ...ListingsActivePageFragment
        }
        allContentfulProperty(
          filter: { text_status: { eq: "Available" } }
          sort: { fields: date_closing }
        ) {
          nodes {
            ...PropertyFragment
          }
        }
      }
    `
  )

  return (
    <ListingsView
      siteVariables={contentfulWebsiteGlobalVariables}
      pageContent={contentfulPageActiveListings}
      properties={allContentfulProperty.nodes}
      listingType="active"
      location={location}
    />
  )
}

export default ListingsActivePage
